<template>
  <div class="collaborationbrowsing study-active-introduce">
    <ActivityLinkInfo
      :activityInfoData="activityInfoData"
      :infoData="resourceData"
    ></ActivityLinkInfo>
    <!-- <div class="hengxian"></div> -->
    <div class="main_content">
      <div class="title-box">协作文档</div>
      <!-- <div class="five cursor" v-if="textActive == true" @click="showText">
        <span>查看全部</span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="five cursor" v-else-if="textActive == false" @click="showText">
        <span>收起</span>
        <i class="el-icon-arrow-up"></i>
      </div> -->
      <div class="fsbc demoBox" v-if="resourceData.implement_type == 1">
        <div class="df">
          <div v-if="resourceData.doc_type == 'sheet'" class="item_img_box">
            <img src="@/assets/imgFIle_4/excel.png" alt="" />
            <div class="left_name">在线表格</div>
          </div>
          <div v-if="resourceData.doc_type == 'doc'" class="item_img_box">
            <img src="@/assets/imgFIle_4/word.png" alt="" />
            <div class="left_name">在线文档</div>
          </div>
          <div v-if="resourceData.doc_type == 'slide'" class="item_img_box">
            <img src="@/assets/imgFIle_4/PPT.png" alt="" />
            <div class="left_name">在线幻灯片</div>
          </div>
          <div class="twoBox_div">
            <p class="timu">{{ resourceData.doc_title || "-" }}</p>
            <p>
              主责人：<span class="text_box">{{
                resourceData.anchor_user_name || "-"
              }}</span>
            </p>
            <p>
              协同时间：<span class="text_box">{{
                resourceData.initial_time || "-"
              }}</span>
            </p>
          </div>
        </div>

        <div v-if="$store.state.activity_isJoin && $store.state.activity_isEnable">
          <el-button
            v-if="!resourceData.doc_url && resourceData.anchor_user_id == userId"
            type="primary"
            @click="dialog_doc_show = true"
            >创建文档
          </el-button>
          <el-button
            v-if="resourceData.doc_url"
            type="primary"
            @click="onEditorWord(resourceData.doc_url)"
            >编辑文档</el-button
          >
        </div>
      </div>
      <!-- <div v-if="resourceData.implement_type == '1'">
        <div class="seven">
          <div class="dot"></div>
          <div class="seven_word">参与协作文档</div>
        </div>
      </div> -->
      <!-- 线下区域 -->
      <UnderLineDetail
        :is_from="+is_from"
        :resourceData="resourceData"
        v-else-if="resourceData.implement_type == '2'"
        @getResourceData="getResourceData"
        :activityId="+activityId"
        :linkId="+id"
      />
    </div>

    <!-- 扫码 -->
    <PublicBoxMaskDialog
      v-if="doc_DialogShow"
      DialogTitle=""
      :DialogShow="doc_DialogShow"
      @DialogCloseEvente="doc_DialogShow = false"
      DialogWidth="1200px"
    >
      <template slot="contentMinBox">
        <div
          style="width: 100%; height: 98%"
          element-loading-text="资源加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
        >
          <iframe
            :src="iframe_src"
            width="100%"
            height="100%"
            seamless
            frameborder="0"
          ></iframe>
        </div>
      </template>
    </PublicBoxMaskDialog>

    <el-dialog
      title="创建协同文档"
      width="1200px"
      :close-on-click-modal="false"
      :visible.sync="dialog_doc_show"
    >
      <el-form
        :model="docData"
        :rules="linkParamsRules"
        ref="linkForm"
        label-width="140px"
        class="link-el-form"
      >
        <el-form-item label="文档类型：" prop="doc_type">
          <el-radio-group v-model="docData.doc_type">
            <el-radio label="sheet">在线表格</el-radio>
            <el-radio label="doc">在线文档</el-radio>
            <el-radio label="slide">在线幻灯片</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="bottom-button-box">
        <el-button @click="dialog_doc_show = false">取消</el-button>
        <el-button type="primary" @click="submitButton()"
          >提交
          <span class="temp_class_span_div">-793</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UnderLineDetail from "../components/underlineDetail/index.vue";
import { ActiveHjDetail } from "@/api/teacherStudio";
import * as researchApi from "@/api/researchApi.js";
import ActivityLinkInfo from "../components/activityLinkInfo.vue";
export default {
  components: {
    UnderLineDetail,
    ActivityLinkInfo,
  },
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      docData: {
        doc_type: "",
      },
      linkParamsRules: {
        doc_type: [
          {
            required: true,
            message: "请选择文档类型",
            trigger: "change",
          },
        ],
      },
      dialog_doc_show: false,
      doc_DialogShow: false,
      iframe_src: "https://www.teachercamp.net/api/tencent/docs_url?user_id=96560",
      // 活动Id
      activityId: "",
      // 环节id
      id: "",
      // 资源浏览数据
      resourceData: {},
      // 当前登录的用户名
      username: "",
      // 当前登录的用户id
      userId: "",
      // 文本是否隐藏
      textActive: true,

      //线下表单
      xianxiaform: {
        name: "",
        accessoryId: "",
        url: "",
      },
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    //提交 在线文档
    submitButton() {
      this.$refs["linkForm"].validate(async (valid) => {
        if (valid) {
          let locationObj = window.location.origin;
          let locationHref = `${locationObj}/#/pageAutoClose`;
          try {
            let userId = window.localStorage.getItem("id");
            await researchApi.getUser_tencent_docs_login({ user_id: userId });
          } catch (err) {
            if (err?.data?.data?.jump_url) {
              let skipSrc = `${err.data.data.jump_url}&skip_url=${encodeURIComponent(
                locationHref
              )}`;
              window.open(skipSrc, "_blank");
              return false;
            }
          }
          let reqObj = {};
          reqObj["doc_type"] = this.docData.doc_type;
          reqObj["id"] = this.id;
          reqObj["is_personid"] = this.resourceData.is_personid;
          researchApi
            .getUser_activitylink_docs_addTencentDocs(reqObj)
            .then((res) => {
              this.init();
              this.dialog_doc_show = false;
            })
            .catch((err) => {});
        }
      });
    },
    // 初始化函数
    init() {
      this.activityId = this.$route.query.activityId;
      this.id = this.$route.query.id;
      this.is_from = this.$route.query.is_from || 1;
      this.username = window.localStorage.getItem("username");
      this.userId = window.localStorage.getItem("id");
      this.getResourceData();
    },
    //请求后台获取资源浏览数据
    getResourceData() {
      if (this.is_from == 1) {
        this.$axios
          .get(`activity/${this.activityId}/activityLink/${this.id}`)
          .then((res) => {
            var resdata = res.data;
            this.resourceData = resdata.data;
            console.log(
              JSON.parse(JSON.stringify(this.resourceData)),
              "【 this.resourceData 】"
            );
          });
      } else {
        ActiveHjDetail(this.activityId, this.id).then((res) => {
          this.resourceData = res.data;
          console.log(
            JSON.parse(JSON.stringify(this.resourceData)),
            "【 this.resourceData 】"
          );
        });
      }
    },
    // 文本显示与隐藏函数
    showText() {
      if (this.textActive == true) {
        this.textActive = false;
      } else if (this.textActive == false) {
        this.textActive = true;
      }
    },
    // 编辑文档
    async onEditorWord(doc_url) {
      let locationObj = window.location.origin;
      let locationHref = `${locationObj}/#/pageAutoClose`;
      try {
        let userId = window.localStorage.getItem("id");
        let reqObj = {
          user_id: userId,
          activity_link_id: this.id,
          skip_url: locationHref,
          is_personid: this.is_from,
        };
        let res_1 = await researchApi.getUser_tencent_docs_url_get(reqObj);
        window.open(res_1.data.data.url, "_blank");
      } catch (err) {
        if (err?.data?.data?.jump_url) {
          let skipSrc = `${err.data.data.jump_url}&skip_url=${encodeURIComponent(
            locationHref
          )}`;
          window.open(skipSrc, "_blank");
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.collaborationbrowsing {
  .hengxian {
    width: 100%;
    height: 1px;
    background: #ececec;
  }
  .main_content {
    .option_box_div {
      margin-left: auto;
      font-size: 16px;
      color: #333333;
      /deep/.el-input__inner {
        background-color: #f4fbff;
      }
    }
    .five {
      color: #3489fe;
      margin-bottom: 36px;
      span {
        margin-right: 11px;
      }
    }
    .six {
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;
    }
    .item_img_box {
      width: 68px;
      height: 80px;
      margin-right: 24px;
      position: relative;
      img {
        width: 68px !important;
        height: 80px !important;
      }
      .left_name {
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 12px;
        line-height: 26px;
        height: 26px;
        font-weight: bold;
        color: #fff;
        text-align: center;
      }
    }
    .demoBox {
      border: 1px solid #ebebeb;
      padding: 21px 21px;
      border-radius: 6px;
      .timu {
        font-size: 18px;
        color: #333;
        font-weight: 600;
        padding-bottom: 10px;
        padding-top: 0;
      }
      img {
        width: 68px;
        height: 60px;
        margin-right: 20px;
      }
    }
    .seven {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .dot {
        width: 6px;
        height: 6px;
        background: #3489fe;
        border-radius: 50%;
        margin-right: 14px;
      }
      .seven_word {
        color: #1a1a1a;
      }
    }
    .eight {
      width: 985px;
      height: 112px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .images {
        margin-left: 33px;
        margin-right: 27px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .eight_word {
        width: 582px;
        p:nth-child(1) {
          margin-bottom: 5px;
        }
        .eight_word_one {
          color: #999999;
          font-size: 14px;
          display: flex;
          align-items: center;
          .shuxian {
            width: 1px;
            height: 11px;
            background: #ebebeb;
            margin: 0 20px;
          }
        }
      }
      .eight_button1 {
        width: 120px;
        height: 34px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        border-radius: 6px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .eight_button2 {
        width: 120px;
        height: 34px;
        background: #3489fe;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }
    }
    .nine {
      width: 985px;
      height: 112px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .images {
        margin-left: 33px;
        margin-right: 27px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .nine_word {
        p {
          margin-bottom: 5px;
        }
        .nine_word_one {
          font-size: 14px;
          color: #999999;
          span:nth-child(1) {
            color: #3489fe;
          }
          span:nth-child(2) {
            margin-left: 25px;
            color: #3489fe;
          }
        }
      }
    }
    .ten {
      margin-bottom: 27px;
      font-size: 18px;
      font-weight: 700;
      color: #1a1a1a;
    }
    .eleven {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .eleven_word {
        color: #2b2b2b;
        margin-right: 16px;
      }
      .images {
        width: 120px;
        height: 116px;
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .twelve {
      display: flex;
      align-items: center;
      .twelve_word {
        color: #2b2b2b;
        margin-right: 16px;
      }
      .twelve_input {
        width: 400px;
      }
    }
    .thirteen {
      margin-top: 50px;
      margin-left: 96px;
    }
  }
}
.bottom-button-box {
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.twoBox_div {
  color: #999;
  .text_box {
    color: #333;
  }
  > P {
    margin-top: 8px;
  }
  .timu {
    margin-top: 0;
  }
}
</style>
